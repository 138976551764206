export default [
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%B9%D9%87%D8%AF%20%D8%A7%D9%84%D8%A3%D8%B5%D8%AF%D9%82%D8%A7%D8%A1.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "عهد الأصدقاء",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%B9%D9%87%D8%AF%20%D8%A7%D9%84%D8%A3%D8%B5%D8%AF%D9%82%D8%A7%D8%A1.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A7%D9%84%D9%82%D9%86%D8%A7%D8%B5.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "القناص",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A7%D9%84%D9%82%D9%86%D8%A7%D8%B5.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%AF%D8%B1%D9%88%D8%A8%20%D8%B1%D9%8A%D9%85%D9%8A.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "دروب ريمي",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%AF%D8%B1%D9%88%D8%A8%20%D8%B1%D9%8A%D9%85%D9%8A.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A3%D9%85%D9%8A%20%D9%83%D9%85%20%D8%A3%D9%87%D9%88%D8%A7%D9%87%D8%A7%20-%20%D8%B1%D9%8A%D9%85%D9%8A.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "أمي كم أهواها - ريمي",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A3%D9%85%D9%8A%20%D9%83%D9%85%20%D8%A3%D9%87%D9%88%D8%A7%D9%87%D8%A7%20-%20%D8%B1%D9%8A%D9%85%D9%8A.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A8%D8%A7%D8%AA%20%D9%85%D8%A7%D9%86.jpg?alt=media",
    artist: "Tarek Al Arabi Tourgane",
    title: "بات مان",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A8%D8%A7%D8%AA%20%D9%85%D8%A7%D9%86.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A7%D9%84%D9%85%D9%82%D8%A7%D8%AA%D9%84%20%D8%A7%D9%84%D9%86%D8%A8%D9%8A%D9%84.jpg?alt=media",
    artist: "Tarek Al Arabi Tourgane & Rasha Rizk",
    title: "المقاتل النبيل",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A7%D9%84%D9%85%D9%82%D8%A7%D8%AA%D9%84%20%D8%A7%D9%84%D9%86%D8%A8%D9%8A%D9%84.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D9%81%D9%8A%D9%84%20%D8%A8%D8%A7%D8%A8%D8%A7%D8%B1.jpg?alt=media",
    artist: "Tarek Al Arabi Tourgane & Rasha Rizk",
    title: "فيل بابار",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D9%81%D9%8A%D9%84%20%D8%A8%D8%A7%D8%A8%D8%A7%D8%B1.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D9%87%D8%B2%D9%8A%D9%85%20%D8%A7%D9%84%D8%B1%D8%B9%D8%AF.jpg?alt=media",
    artist: "Tarek Al Arabi Tourgane",
    title: "هزيم الرعد",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D9%87%D8%B2%D9%8A%D9%85%20%D8%A7%D9%84%D8%B1%D8%B9%D8%AF.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A3%D8%A8%D8%B7%D8%A7%D9%84%20%D8%A7%D9%84%D8%AF%D9%8A%D8%AC%D9%8A%D8%AA%D8%A7%D9%84%20-%20%D8%A7%D9%84%D8%AC%D8%B2%D8%A1%20%D8%A7%D9%84%D8%A3%D9%88%D9%84%20%D9%88%20%D8%A7%D9%84%D8%AB%D8%A7%D9%86%D9%8A.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "أبطال الديجيتال - الجزء الأول و الثاني",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A3%D8%A8%D8%B7%D8%A7%D9%84%20%D8%A7%D9%84%D8%AF%D9%8A%D8%AC%D9%8A%D8%AA%D8%A7%D9%84%20-%20%D8%A7%D9%84%D8%AC%D8%B2%D8%A1%20%D8%A7%D9%84%D8%A3%D9%88%D9%84%20%D9%88%20%D8%A7%D9%84%D8%AB%D8%A7%D9%86%D9%8A.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A3%D8%A8%D8%B7%D8%A7%D9%84%20%D8%A7%D9%84%D8%AF%D9%8A%D8%AC%D9%8A%D8%AA%D8%A7%D9%84%20%D8%A7%D9%84%D8%AC%D8%B2%D8%A1%20%D8%A7%D9%84%D8%AB%D8%A7%D9%84%D8%AB.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "أبطال الديجيتال الجزء الثالث",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A3%D8%A8%D8%B7%D8%A7%D9%84%20%D8%A7%D9%84%D8%AF%D9%8A%D8%AC%D9%8A%D8%AA%D8%A7%D9%84%20%D8%A7%D9%84%D8%AC%D8%B2%D8%A1%20%D8%A7%D9%84%D8%AB%D8%A7%D9%84%D8%AB.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D9%84%D8%A7%20%D8%AA%D8%A8%D9%83%D9%8A%20%D9%8A%D8%A7%20%D8%B5%D8%BA%D9%8A%D8%B1%D9%8A.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "لا تبكي يا صغيري",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D9%84%D8%A7%20%D8%AA%D8%A8%D9%83%D9%8A%20%D9%8A%D8%A7%20%D8%B5%D8%BA%D9%8A%D8%B1%D9%8A.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%B3%D9%8A%D9%85%D8%A8%D8%A7.jpg?alt=media",
    artist: "Tarek Al Arabi Tourgane",
    title: "سيمبا",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%B3%D9%8A%D9%85%D8%A8%D8%A7.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%AF%D8%B1%D8%A7%D8%BA%D9%88%D9%86%20%D8%A8%D9%88%D9%84.jpg?alt=media",
    artist: "Tarek Al Arabi Tourgane",
    title: "دراغون بول",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%AF%D8%B1%D8%A7%D8%BA%D9%88%D9%86%20%D8%A8%D9%88%D9%84.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D9%83%D9%88%D9%86%D8%A7%D9%86.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "كونان",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D9%83%D9%88%D9%86%D8%A7%D9%86.mp3?alt=media",
  },

  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A8%D8%A7%D8%B5%20%D8%A7%D9%84%D9%85%D8%AF%D8%B1%D8%B3%D8%A9%20%D8%A7%D9%84%D8%B9%D8%AC%D9%8A%D8%A8.jpg?alt=media",
    artist: "Tarek Al Arabi Tourgane & Rasha Rizk",
    title: "باص المدرسة العجيب",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A8%D8%A7%D8%B5%20%D8%A7%D9%84%D9%85%D8%AF%D8%B1%D8%B3%D8%A9%20%D8%A7%D9%84%D8%B9%D8%AC%D9%8A%D8%A8.mp3?alt=media",
  },

  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A8%D9%88%D9%83%D9%8A%D9%85%D9%88%D9%86.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "بوكيمون",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A8%D9%88%D9%83%D9%8A%D9%85%D9%88%D9%86.mp3?alt=media",
  },

  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D9%85%D8%A7%D9%88%D9%83%D9%84%D9%8A%20%D9%81%D8%AA%D9%89%20%D8%A7%D9%84%D8%A3%D8%AF%D8%BA%D8%A7%D9%84.jpg?alt=media",
    artist: "Tarek Al Arabi Tourgane",
    title: "ماوكلي فتى الأدغال",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D9%85%D8%A7%D9%88%D9%83%D9%84%D9%8A%20%D9%81%D8%AA%D9%89%20%D8%A7%D9%84%D8%A3%D8%AF%D8%BA%D8%A7%D9%84.mp3?alt=media",
  },

  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%B4%D9%85%D8%B3%20%D8%B3%D8%B7%D8%B9%D8%AA%20%D9%88%D8%A7%D9%84%D8%AC%D9%88%20%D8%AC%D9%85%D9%8A%D9%84%20%D9%87%D8%B0%D8%A7%20%D8%A7%D9%84%D9%8A%D9%88%D9%85.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "شمس سطعت والجو جميل هذا اليوم",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%B4%D9%85%D8%B3%20%D8%B3%D8%B7%D8%B9%D8%AA%20%D9%88%D8%A7%D9%84%D8%AC%D9%88%20%D8%AC%D9%85%D9%8A%D9%84%20%D9%87%D8%B0%D8%A7%20%D8%A7%D9%84%D9%8A%D9%88%D9%85.mp3?alt=media",
  },

  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D9%84%D9%86%20%D8%A7%D8%B9%D9%88%D8%AF%20%D9%84%D9%84%D9%88%D8%B1%D8%A7%D8%A1%20%D8%A7%D9%8A%D8%B1%D9%88%D9%83%D8%A7.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "لن اعود للوراء - ايروكا",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D9%84%D9%86%20%D8%A7%D8%B9%D9%88%D8%AF%20%D9%84%D9%84%D9%88%D8%B1%D8%A7%D8%A1%20%D8%A7%D9%8A%D8%B1%D9%88%D9%83%D8%A7.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%B1%D8%B3%D9%85%D8%AA%20%D8%A8%D9%8A%D8%AA%D8%A7%20%D8%B5%D8%BA%D9%8A%D8%B1%D8%A7%20%D8%A3%D8%B3%D9%85%D9%8A%D8%AA%D9%87%20%D8%A7%D9%84%D8%A3%D8%AD%D9%84%D8%A7%D9%85%20%20-%20%D8%A7%D9%8A%D8%B1%D9%88%D9%83%D8%A7.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "رسمت بيتا صغيرا أسميته الأحلام  - ايروكا",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%B1%D8%B3%D9%85%D8%AA%20%D8%A8%D9%8A%D8%AA%D8%A7%20%D8%B5%D8%BA%D9%8A%D8%B1%D8%A7%20%D8%A3%D8%B3%D9%85%D9%8A%D8%AA%D9%87%20%D8%A7%D9%84%D8%A3%D8%AD%D9%84%D8%A7%D9%85%20%20-%20%D8%A7%D9%8A%D8%B1%D9%88%D9%83%D8%A7.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D9%83%D9%85%20%D8%AA%D8%A8%D8%AF%D9%88%20%D8%A7%D9%84%D8%AF%D9%86%D9%8A%D8%A7%20%D8%A7%D8%AC%D9%85%D9%84%20-%20%D8%A5%D9%8A%D8%B1%D9%88%D9%83%D8%A7.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "كم تبدو الدنيا اجمل - إيروكا",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D9%83%D9%85%20%D8%AA%D8%A8%D8%AF%D9%88%20%D8%A7%D9%84%D8%AF%D9%86%D9%8A%D8%A7%20%D8%A7%D8%AC%D9%85%D9%84%20-%20%D8%A5%D9%8A%D8%B1%D9%88%D9%83%D8%A7.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A3%D8%B5%D8%AF%D9%82%D8%A7%D8%A1%20%D8%A3%D8%B5%D8%AF%D9%82%D8%A7%D8%A1%20-%20%D8%A5%D9%8A%D8%B1%D9%88%D9%83%D8%A7.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "أصدقاء أصدقاء - إيروكا",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A3%D8%B5%D8%AF%D9%82%D8%A7%D8%A1%20%D8%A3%D8%B5%D8%AF%D9%82%D8%A7%D8%A1%20-%20%D8%A5%D9%8A%D8%B1%D9%88%D9%83%D8%A7.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A7%D9%84%D8%B3%D8%B1%D8%A7%D8%A8.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "السراب",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A7%D9%84%D8%B3%D8%B1%D8%A7%D8%A8.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A7%D9%84%D8%B4%D8%A8%D8%AD.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "الشبح",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A7%D9%84%D8%B4%D8%A8%D8%AD.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A7%D9%8A%D8%B1%D9%88%D9%86%20%D9%83%D9%8A%D8%AF.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "ايرون كيد",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A7%D9%8A%D8%B1%D9%88%D9%86%20%D9%83%D9%8A%D8%AF.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A7%D9%84%D8%AF%D8%B1%D8%A7%D8%AC%20%D8%A7%D9%84%D8%B4%D9%87%D8%A7%D8%A8.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "الدراج الشهاب",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A7%D9%84%D8%AF%D8%B1%D8%A7%D8%AC%20%D8%A7%D9%84%D8%B4%D9%87%D8%A7%D8%A8.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%B4%D8%B9%D9%84%D8%A9%20%D8%B1%D9%8A%D9%83%D8%A7.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "شعلة ريكا",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%B4%D8%B9%D9%84%D8%A9%20%D8%B1%D9%8A%D9%83%D8%A7.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A7%D9%84%D9%82%D8%B7%20%D8%A7%D9%84%D8%A7%D8%B3%D9%88%D8%AF.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "القط الاسود",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A7%D9%84%D9%82%D8%B7%20%D8%A7%D9%84%D8%A7%D8%B3%D9%88%D8%AF.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A3%D8%A8%D8%B7%D8%A7%D9%84%20%D8%A7%D9%84%D9%83%D8%B1%D8%A9.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "أبطال الكرة",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A3%D8%A8%D8%B7%D8%A7%D9%84%20%D8%A7%D9%84%D9%83%D8%B1%D8%A9.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%AF%D8%B1%D8%A7%D9%8A%D9%85%D9%88%D9%86.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "درايمون",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%AF%D8%B1%D8%A7%D9%8A%D9%85%D9%88%D9%86.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%B3%D8%A7%D8%A8%D9%82%20%D9%88%D9%84%D8%A7%D8%AD%D9%82.jpg?alt=media",
    artist: "Tarek Al Arabi Tourgane",
    title: "سابق ولاحق",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%B3%D8%A7%D8%A8%D9%82%20%D9%88%D9%84%D8%A7%D8%AD%D9%82.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A3%D9%86%D8%A7%20%D9%88%D8%A3%D8%AE%D9%8A.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "أنا وأخي",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A3%D9%86%D8%A7%20%D9%88%D8%A3%D8%AE%D9%8A.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A3%D9%86%D8%A7%20%D9%88%D8%A3%D8%AE%D8%AA%D9%8A.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "أنا وأختي",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A3%D9%86%D8%A7%20%D9%88%D8%A3%D8%AE%D8%AA%D9%8A.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%A7%D9%84%D8%A8%D8%A4%D8%B3%D8%A7%D8%A1.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "البؤساء",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%A7%D9%84%D8%A8%D8%A4%D8%B3%D8%A7%D8%A1.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%B3%D8%A7%D9%84%D9%8A.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "سالي",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%B3%D8%A7%D9%84%D9%8A.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%B1%D9%88%D8%A8%D9%86%20%D9%87%D9%88%D8%AF.jpg?alt=media",
    artist: "Tarek Al Arabi Tourgane",
    title: "روبن هود",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%B1%D9%88%D8%A8%D9%86%20%D9%87%D9%88%D8%AF.mp3?alt=media",
  },
  {
    img:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fimages%2F%D8%BA%D9%8A%D9%85%D8%A9.jpg?alt=media",
    artist: "Rasha Rizk",
    title: "غيمة",
    src:
      "https://firebasestorage.googleapis.com/v0/b/personal-projects-c3614.appspot.com/o/golden-generation%2Fmusic%2F%D8%BA%D9%8A%D9%85%D8%A9.mp3?alt=media",
  },
];
