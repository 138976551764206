import React from "react";

function PLayerDetails({ songs, ...props }) {
  return (
    <div className="c__player__details">
      <h3>{songs[props.currentSongIndex].title}</h3>
      <p>{songs[props.currentSongIndex].artist}</p>
    </div>
  );
}

export default PLayerDetails;
