import React, { useState, useEffect, useRef } from "react";
import PlayerControls from "./PlayerControls";
import PlayerDetails from "./PLayerDetails";
import ProgressTime from "./ProgressTime";

function Player({ songs, ...props }) {
  const audioEl = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [trackEnded, setTrackEnded] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (isPlaying) {
      let playPromise = audioEl.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            audioEl.current.play();
            setCurrentTime(audioEl.current.currentTime);
            setDuration(audioEl.current.duration);
          })
          .catch((error) => {
            setIsPlaying(isPlaying);
          });
      }
    } else {
      let pausePromise = audioEl.current.pause();
      if (pausePromise !== undefined) {
        pausePromise
          .then((_) => {
            audioEl.current.pause();
            setCurrentTime(audioEl.current.currentTime);
            setDuration(audioEl.current.duration);
          })
          .catch((error) => {
            setIsPlaying(isPlaying);
          });
      }
    }
  });

  const nextSongs = (next = true) => {
    if (next)
      props.setCurrentSongIndex(() => {
        let tmp = props.currentSongIndex;
        tmp++;

        if (tmp > songs.length - 1) tmp = 0;
        setCurrentTime(0);
        return tmp;
      });
    else
      props.setCurrentSongIndex(() => {
        let tmp = props.currentSongIndex;
        tmp--;

        if (tmp < 0) tmp = songs.length - 1;
        setCurrentTime(0);
        return tmp;
      });
  };

  const skip10Sec = (next = true) => {
    try {
      if (next) audioEl.current.currentTime += 10;
      else audioEl.current.currentTime -= 10;
    } catch (error) {
      console.log("Error Message: " + error);
    }
  };

  const loading = () => {
    return "./loading.gif";
  };

  return (
    <div className="wrapper">
      <audio
        src={songs[props.currentSongIndex].src}
        ref={audioEl}
        onEnded={() => setTrackEnded(true)}
      />
      <div className="c__player">
        {/* Song status */}
        <div className="song__status">
          <p>now playing</p>
        </div>

        {/* Image */}
        <div className="border__img">
          <div className="img__frame">
            <img
              src={
                !songs[props.currentSongIndex].img
                  ? loading
                  : songs[props.currentSongIndex].img
              }
              alt={songs[props.currentSongIndex].title}
            />
          </div>
        </div>

        {/* Details */}
        <PlayerDetails
          songs={songs}
          currentSongIndex={props.currentSongIndex}
        />
        {/* Progress Time */}
        <ProgressTime
          currentTime={currentTime}
          duration={duration}
          setCurrentTime={setCurrentTime}
          skip10Sec={skip10Sec}
        />

        {/* Player Controls */}
        <PlayerControls
          songs={songs}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          nextSongs={nextSongs}
          skip10Sec={skip10Sec}
          audioEl={audioEl}
          trackEnded={trackEnded}
          setTrackEnded={setTrackEnded}
        />
        <div className="next__song">
          <p>
            <span style={{ fontWeight: "600" }}>Next up: </span>{" "}
            <span id="next__song">{songs[props.nextSongIndex].title}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Player;
