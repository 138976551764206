import React, { useState, useEffect } from "react";
import Player from "./components/Player";
import SongsList from "./songs";

function App() {
  const [songs] = useState(SongsList);
  const [currentSongIndex, setCurrentSongIndex] = useState(() => {
    if (!localStorage.getItem("currentSavedIndex")) return 0;
    else return localStorage.getItem("currentSavedIndex");
  });

  const [nextSongIndex, setNextSongIndex] = useState(() => {
    if (!localStorage.getItem("nextSavedIndex")) return currentSongIndex + 1;
    else return localStorage.getItem("nextSavedIndex");
  });

  useEffect(() => {
    setNextSongIndex(() => {
      if (currentSongIndex + 1 > songs.length - 1) return 0;
      return currentSongIndex + 1;
    });
  }, [currentSongIndex, songs]);

  useEffect(() => {
    localStorage.setItem("currentSavedIndex", currentSongIndex);
    localStorage.setItem("nextSavedIndex", nextSongIndex);
  }, [nextSongIndex, currentSongIndex]);

  return (
    <>
      <Player
        songs={songs}
        currentSongIndex={currentSongIndex}
        setCurrentSongIndex={setCurrentSongIndex}
        nextSongIndex={nextSongIndex}
      />
    </>
  );
}

export default App;
