import React, { useRef, useState, useEffect } from "react";

function ProgressTime({ currentTime, duration, setCurrentTime }) {
  const currentTimeRange = useRef(null);
  const [rangeStyle, setRangeStyle] = useState("");

  const sliderStyleProps = {
    fill: "#0B1EDF",
    background: "#d6d8dad3",
  };

  function applyFill() {
    const percentage = (100 * currentTimeRange.current.value) / duration;
    const bg = `linear-gradient(90deg, ${
      sliderStyleProps.fill
    } ${percentage}%, ${sliderStyleProps.background} ${percentage + 0.1}%)`;
    setRangeStyle(bg);
    setCurrentTime(currentTimeRange.current.value);
  }

  function handleChange() {
    setCurrentTime(currentTimeRange.current.value);
    applyFill();
  }

  useEffect(() => {
    applyFill();
  });

  const formatTime = (seconds) => {
    let output =
      ("0" + Math.floor(seconds / 60).toString()).slice(-2) +
      ":" +
      ("0" + Math.floor(seconds % 60).toString()).slice(-2);

    return output;
  };

  return (
    <div className="c__player__time">
      <div className="range__slider">
        <div className="start__timer">{formatTime(currentTime)}</div>
        <input
          id="slider"
          type="range"
          style={{ background: rangeStyle }}
          min={0}
          max={!duration ? 0 : duration}
          step={1}
          ref={currentTimeRange}
          value={currentTime}
          onChange={handleChange}
        />
        <div className="duration__timer">{formatTime(duration)}</div>
      </div>
    </div>
  );
}

export default ProgressTime;
