import React, { useEffect, useState } from "react";
import {
  PlayArrowOutlined,
  PauseOutlined,
  SkipNextOutlined,
  SkipPreviousOutlined,
  Forward10Outlined,
  Replay10Outlined,
} from "@material-ui/icons";
import useKeypress from "../hooks/useKeypress";

function PlayerControls({ songs, ...props }) {
  const [isSpacePressed, setIsSpacePressed] = useState(false);
  const [isRightArrowPressed, setIsRightArrowPressed] = useState(false);
  const [isLeftArrowPressed, setIsLeftArrowPressed] = useState(false);
  const [isUpArrowPressed, setIsUpArrowPressed] = useState(false);
  const [isDownArrowPressed, setIsDownArrowPressed] = useState(false);

  useEffect(() => {
    if (props.trackEnded) {
      props.setTrackEnded(false);
      props.setIsPlaying(false);
      props.nextSongs();
      props.setIsPlaying(true);
    }
  });

  {
    /* Spacebar press event  */
  }
  useKeypress(
    " ",
    () => {
      props.setIsPlaying(!props.isPlaying);
      setIsSpacePressed(!isSpacePressed);
      console.log("Space Pressed");
    },
    isSpacePressed
  );

  {
    /* right arrow next press event */
  }
  useKeypress(
    "ArrowRight",
    () => {
      props.skip10Sec();
      setIsRightArrowPressed(!isRightArrowPressed);
      console.log("Right Arrow Pressed");
    },
    isRightArrowPressed
  );

  {
    /* left arrow next press event */
  }
  useKeypress(
    "ArrowLeft",
    () => {
      props.skip10Sec(false);
      setIsLeftArrowPressed(!isLeftArrowPressed);
      console.log("Left Arrow Pressed");
    },
    isLeftArrowPressed
  );

  {
    /* Up arrow (previous song) press event */
  }
  useKeypress(
    "ArrowUp",
    () => {
      props.nextSongs(false);
      setIsUpArrowPressed(!isUpArrowPressed);
      console.log("Up Arrow Pressed");
    },
    isUpArrowPressed
  );

  {
    /* Down arrow (next song) press event */
  }
  useKeypress(
    "ArrowDown",
    () => {
      props.nextSongs();
      setIsDownArrowPressed(!isDownArrowPressed);
      console.log("Down Arrow Pressed");
    },
    isDownArrowPressed
  );

  return (
    <div className="c__player__controls">
      <div className="controls">
        <SkipPreviousOutlined
          className="previous__songs"
          onClick={() => props.nextSongs(false)}
        />
        <Replay10Outlined
          className="forward__backward forward"
          onClick={() => props.skip10Sec(false)}
        />

        {props.isPlaying ? (
          <PauseOutlined
            className="play__pause"
            onClick={() => props.setIsPlaying(!props.isPlaying)}
          />
        ) : (
          <PlayArrowOutlined
            className="play__pause"
            onClick={() => props.setIsPlaying(!props.isPlaying)}
          />
        )}
        <Forward10Outlined
          className="forward__backward backward"
          onClick={props.skip10Sec}
        />

        <SkipNextOutlined className="next__songs" onClick={props.nextSongs} />
      </div>
    </div>
  );
}

export default PlayerControls;
